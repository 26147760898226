import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    }

    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.isAuthenticated) {
    next({ name: 'login' });
  } else if (to.name === 'fileRequests' && !store.getters.user.isFileRequest) {
    next({ name: 'files' });
  } else {
    next();
  }
})

router.beforeResolve((to, from, next) => {
  if (to.path === from.path) {
    window.location.reload()

    return
  }

  if (to.name) {
    // this.$flex.progress.start()
    const pageTitle = router.app.$options.i18n.t(`labels.${to.name}`)

    document.title = `${pageTitle} | ${process.env.VUE_APP_TITLE}`
  }

  const userLang = window.localStorage.getItem('user_lang')

  if (userLang) {
    router.app.$options.i18n.locale = userLang
  }

  next()
})

router.afterEach(() => {
  // this.$flex.progress.done()
})

export default router
