import Vue from '../../main'

export default {
  state: {
    user: null,
    isAuthenticated: false,
    authStatus: null
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => state.isAuthenticated
  },
  mutations: {
    setAuth (state, payload) {
      const { user, token } = payload

      state.isAuthenticated = true
      state.user = user
      Vue.$flex.jwt.setToken(token)
    },
    setLogout (state) {
      state.isAuthenticated = false
      state.user = {}
      state.authStatus = null
    }
  },
  actions: {
    login: async ({ commit }, payload) => {
      const { username, password, recaptchaToken } = payload
      const data = await Vue.$http.post('companyApp/login', {
        username,
        password,
        recaptchaToken
      })

      commit('setAuth', data)
    },
    newUser: async (_context, payload) => {
      await Vue.$http.post('auth/signup', payload)
    },
    fakeLogin: async ({ commit }) => {
      commit('setAuth', {
        user: {},
        // eslint-disable-next-line spellcheck/spell-checker
        token: 'ea2cb7cd-fd98-47a0-8540-05cce28bedbc'
      })
    },
    logout: async ({ commit, state }) => {
      if (state.isAuthenticated) {
        Vue.$flex.jwt.destroyAuthData()
        commit('setLogout')
      }

      if (Vue.$router.currentRoute.name !== 'login') {
        Vue.$router.push({ name: 'login' })
      }
    }
  }
}
