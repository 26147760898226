import i18n from '@/plugins/i18n'

const routes = [
  {
    path: `/${i18n.t('routes.user')}/`,
    component: () => import('@/layouts/user/index.vue'),
    children: [
      {
        path: i18n.t('routes.login'),
        name: 'login',
        component: () => import('@/views/user/Login.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/dashboard/index.vue'),
    redirect: `/${i18n.t('routes.files')}`,
    children: [
      {
        path: i18n.t('routes.files'),
        name: 'files',
        component: () => import('@/views/file/Index.vue')
      },
      {
        path: `/${i18n.t('routes.fileRequests')}`,
        name: 'fileRequests',
        component: () => import('@/views/fileRequest/Index.vue')
      },
      {
        path: '/:slug/upload',
        name: 'upload',
        component: () => import('@/views/user/Upload.vue')
      }
    ]
  }
  // {
  //   path: '/:slug/upload',
  //   name: 'upload',
  //   component: () => import('@/views/user/Upload.vue')
  // }
]

export default routes
