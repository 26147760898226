import Vue from 'vue'

import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import App from '@/App.vue'

import '@/plugins/jquery'
import '@/plugins/flex-admin'

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
